import React, { useState, useRef, useEffect } from "react";
import { Button, Box, Typography, Stack, IconButton, Chip } from "@mui/material";
import { Cancel, CancelOutlined, Check, Close, Mic, Pause, PlayArrow } from "@mui/icons-material";
import Lottie from "react-lottie-player";
import recordingAnimation from '../utils/animation2.json';

function AudioRecorder({ saveRecording, disabled, resetRecording }) {
  const [recordingStarted, setRecordingStarted] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isRecorded, setIsRecorded] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isLameReady, setIsLameReady] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const mediaRecorderRef = useRef(null);
  const audioContextRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const analyserRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdnjs.cloudflare.com/ajax/libs/lamejs/1.2.0/lame.min.js";
    script.async = true;
    script.onload = () => setIsLameReady(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (resetRecording) {
      stopRecording();
    }
    return () => {
      stopRecording();
    };
  }, [resetRecording]);

  const startRecording = async () => {
    setRecordingStarted(true);
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      sourceNodeRef.current = audioContextRef.current.createMediaStreamSource(stream);
      analyserRef.current = audioContextRef.current.createAnalyser();
      sourceNodeRef.current.connect(analyserRef.current);

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.start();
      setIsRecording(true);
      setIsPaused(false);
      startTimer();
      console.log("Recording started");
    } catch (err) {
      console.error("Error starting recording:", err);
    }
  };

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setRecordingTime((prevTime) => prevTime + 1);
    }, 1000);
  };

  const pauseRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
      clearInterval(timerRef.current);
      console.log("Recording paused");
    }
  };

  const resumeRecording = () => {
    if (mediaRecorderRef.current && isPaused) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
      startTimer();
      console.log("Recording resumed");
    }
  };

  const stopRecording = () => {
    console.log('stopping recording');
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      clearInterval(timerRef.current);
      setRecordingTime(0);
      if (sourceNodeRef.current) {
        sourceNodeRef.current.disconnect();
      }
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      setIsRecording(false);
      setIsPaused(false);
      setIsRecorded(true);
      console.log("Recording stopped");
    }
  };

  const cancelRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      clearInterval(timerRef.current);
      setRecordingTime(0);
      setIsRecording(false);
      setIsPaused(false);
      audioChunksRef.current = [];
      console.log("Recording canceled");
    }
    setRecordingStarted(false);
    setIsRecorded(false);
    saveRecording(null);
  };

  const convertToMp3AndSave = async (audioBlob) => {
    if (!isLameReady) {
      console.error("lamejs is not loaded");
      return;
    }

    const arrayBuffer = await audioBlob.arrayBuffer();
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    const channels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const samples = audioBuffer.getChannelData(0);

    // eslint-disable-next-line no-undef
    const mp3encoder = new lamejs.Mp3Encoder(channels, sampleRate, 128);
    const mp3Data = [];

    const sampleBlockSize = 1152;
    for (let i = 0; i < samples.length; i += sampleBlockSize) {
      const sampleChunk = samples.subarray(i, i + sampleBlockSize);
      const mp3buf = mp3encoder.encodeBuffer(floatTo16BitPCM(sampleChunk));
      if (mp3buf.length > 0) {
        mp3Data.push(new Int8Array(mp3buf));
      }
    }

    const mp3buf = mp3encoder.flush();
    if (mp3buf.length > 0) {
      mp3Data.push(new Int8Array(mp3buf));
    }

    const mp3Blob = new Blob(mp3Data, { type: "audio/mp3" });
    saveRecording(mp3Blob);
  };

  const floatTo16BitPCM = (input) => {
    const output = new Int16Array(input.length);
    for (let i = 0; i < input.length; i++) {
      const s = Math.max(-1, Math.min(1, input[i]));
      output[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
    }
    return output;
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <>
    {isRecording && (
        <Typography variant="h6" align="center" mt={2}>
          {formatTime(recordingTime)}
        </Typography>
      )}
      <Stack position="relative" zIndex={999} component="div" gap={3} direction="row" alignItems="center" justifyContent="center">
        <IconButton sx={{ fontSize: '2.25rem'}} disabled={disabled || (!recordingStarted)} onClick={cancelRecording} size="large" color="primary">
          <Close fontSize="2.25rem" />
        </IconButton>
        {
          !recordingStarted ? (
            <IconButton sx={{ fontSize: '3.25rem'}} disabled={disabled || isRecording} onClick={startRecording} size="large" color="error">
              <Mic fontSize="3.25rem" />
            </IconButton>
          ) : (
            isRecording && !isPaused ? (
              <IconButton sx={{ fontSize: '3.25rem'}} disabled={disabled} onClick={pauseRecording} size="large" color="warning">
                <Pause fontSize="3.25rem" />
              </IconButton>
            ) : (
              <IconButton sx={{ fontSize: '3.25rem'}} disabled={disabled || !isPaused} onClick={resumeRecording} size="large" color="error">
                <Mic fontSize="3.25rem" />
              </IconButton>
            )
          )
        }
        <IconButton sx={{ fontSize: '2.25'}} disabled={disabled || (!isRecorded && !isRecording)} onClick={() => {
          stopRecording();
          setTimeout(() => {
            const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
            convertToMp3AndSave(audioBlob);
          }, 500);
        }} size="large" color="success">
          <Check fontSize="2.25rem" />
        </IconButton>
      </Stack>

      <Stack direction="row" justifyContent="center" alignItems="center">
        {isRecording && (
          <Lottie
            loop
            animationData={recordingAnimation}
            play={!isPaused}
            style={{ width: 120, height: 120, marginTop: '-260px', position: 'relative', zIndex: 1 }}
          />
        )}
      </Stack>
    </>
  );
}

export default AudioRecorder;
