import React, { useEffect, useState } from 'react';
import MyNotesContent from '../MyNotesContent';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CreateNote from '../CreateNote';
import { createNoteInitialState, tourStepsCreateNote } from '../../utils';

const NotesDialog = ({ open, handleClose }) => {
  const [isCreating, setIsCreating] = React.useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [draftNotes, setDraftNotes] = React.useState(
    JSON.parse(localStorage.getItem('notes_drafts')) || []
  );
  const [initialState, setInitialState] = useState(createNoteInitialState);
  const [resetRecording, setResetRecording] = React.useState(false);
  const handleResetRecording = () => setResetRecording(false);

  const handleIsSubmittingChange = (value) => {
    setIsSubmitting(value);
  };
  const handleDialogOpen = (value) => {
    setDialogOpen(value);
  };
  useEffect(() => {
    if (!open) {
      setResetRecording(true);
    }
    handleResetRecording();
    return () => {
      setResetRecording(true);
      handleResetRecording();
    };
  }, [open]);

  useEffect(() => {
    localStorage.setItem('notes_drafts', JSON.stringify(draftNotes));
  }, [draftNotes]);
  return (
    <>
      <Dialog
        scroll='paper'
        maxWidth='md'
        fullWidth
        open={open}
        onClose={!isSubmitting && handleClose}
      >
        {/* {
        isCreating ?  */}
        <CreateNote
          initialState={initialState}
          setInitialState={setInitialState}
          setIsCreating={setIsCreating}
          onIsSubmittingChange={handleIsSubmittingChange}
          handleDialogOpen={handleDialogOpen}
          handleClose={handleClose}
          setDraftNotes={setDraftNotes}
          resetRecording={resetRecording}
        />
        {/* : 
        <MyNotesContent
        open={open}
        setInitialState={setInitialState}
        draftNotes={draftNotes}
        setDraftNotes={setDraftNotes}
        setIsCreating={setIsCreating}
        resetRecording={resetRecording} 
        />
      } */}
      </Dialog>
      <Dialog 
        open={isDialogOpen} 
        onClose={() => setDialogOpen(false)} 
        PaperProps={{
          style: { borderRadius: 12, padding: '10px' }  // Rounded corners and padding for the dialog
        }}
      >
        <DialogTitle sx={{ fontSize: '1.5rem', fontWeight: 'bold', color: "#2e6ff3" }}>
          Something Went Wrong!
        </DialogTitle>
        <DialogContent sx={{ fontSize: '1rem', paddingBottom: '16px' }}>
          Please try again to create note after a few minutes.
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDialogOpen(false)} 
            variant="contained" 
            color="primary" 
            sx={{ 
              color: '#fff', 
              backgroundColor: '#1976d2',
              '&:hover': { backgroundColor: '#1565c0' },
              borderRadius: '8px',
              padding: '8px 16px',
              textTransform: 'none'
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotesDialog;
