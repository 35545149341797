// src/redux/customPhraseSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  noteDetails: {
    note: null,
    summary: null,
    summary_json: null,
    followup: null,
    followupID: null,
    status: '',
  },
  loading: false,
  error: null,
};

const noteDetailsSlice = createSlice({
  name: 'noteDetails',
  initialState,
  reducers: {
    setnoteDetails: (state, action) => {
      const { note, results } = action.payload;
      state.noteDetails = {
        summary_json: note?.body_json || null,
        followup_json: results?.followup_json || null,
        followup: results.followup || null,
        summary: results.summary || null,
        followupID: results.followupID || null,
        status: results.status || null,
        note: note,
      };
    },
    updateNoteDetailsBodyJSON: (state, action) => {
      const { noteDetailType, key, value, index } = action.payload;
      if (
        state.noteDetails[noteDetailType] &&
        state.noteDetails[noteDetailType][key]
      ) {
        key === 'ekg' || key === 'care-update'
          ? (state.noteDetails[noteDetailType][key].content[index].content =
              value)
          : (state.noteDetails[noteDetailType][key].content = value);
      } else {
        console.error('Invalid noteDetailType or key:', noteDetailType, key);
      }
    },
    updateNoteDetails: (state, action) => {
      const { key, value } = action.payload;
      state.noteDetails[key] = {
        ...state.noteDetails[key], 
        [value.key]: value,
      };
    },
    updateNoteStatus: (state, action) => {
      const { key, value } = action.payload;
      state.noteDetails[key] = value;
    },
    resetNoteDetials: (state, action) => {
      state.noteDetails = initialState.noteDetails;
    },
  },
});

export const {
  setnoteDetails,
  updateNoteDetails,
  updateNoteStatus,
  resetNoteDetials,
  updateNoteDetailsBodyJSON,
} = noteDetailsSlice.actions;

export default noteDetailsSlice.reducer;
