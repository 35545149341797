// tutorialSlice.js
import { createSlice } from '@reduxjs/toolkit';

const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState: {
    isTutorialOpen: false,
  },
  reducers: {
    openTutorial: (state) => {
      state.isTutorialOpen = true;
    },
    closeTutorial: (state) => {
      state.isTutorialOpen = false;
    },
  },
});

export const { openTutorial, closeTutorial } = tutorialSlice.actions;

export default tutorialSlice.reducer;
