import { Box, Button, Card, CircularProgress, Menu, MenuItem, styled, Checkbox, TextField, Typography, Stack } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateNoteDetailsBodyJSON } from '../../slices/noteDetialsSlice';
import { useCopyToClipboard } from '../../hooks/useCopyFromClipBoard';
import { getCopyContentFromValue, formatContentWithLocalTime } from '../../utils';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';

const StyledTextArea = styled('textarea')(({ theme, heading }) => ({
  width: '100%',
  padding: '8px',
  background: 'transparent',
  border: 'none',
  outline: 'none',
  resize: 'none',
  overflow: 'hidden',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSize,
  color: heading ? '#2E6FF3' : theme.palette.text.primary,
  fontWeight: heading ? 'bold' : 'normal',
  '&:disabled': {
    backgroundColor: 'transparent',
    color: heading ? '#2E6FF3' : theme.palette.text.primary,
    '-webkit-text-fill-color': heading ? '#2E6FF3' : theme.palette.text.primary,
  },
}));

const StyledInput = styled('input')(({ theme }) => ({
  width: '100%',
  background: 'transparent',
  padding: '8px',
  border: 'none',
  outline: 'none',
  color: '#2E6FF3',
  fontWeight: 'bold',
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.fontSize,
  '&:disabled': {
    backgroundColor: 'transparent',
    color: '#2E6FF3',
    '-webkit-text-fill-color': '#2E6FF3',
  },
}));

const debounceDelay = 500;

const SingleContent = React.memo(({ disableHeadingEdit, copyChecked, handleExcludedHeadings, noteDetailType, value, itemKey: key, setIsEdited }) => {
  const updatedValue = {
    ...value,
    content: formatContentWithLocalTime(value.content)
  };
  const dispatch = useDispatch();
  const [isDelayed, setIsDelayed] = useState(false);
  const textAreaRef = useRef(null); // Ref to track the text area
  const [anchorEl, setAnchorEl] = useState(null); // For opening the menu
  const [cursorPosition, setCursorPosition] = useState(0);
  const { phrases, loading: loadingPhrases } = useSelector(state => state.customPhrases);
  const adjustTextAreaHeight = useCallback(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, []);

  useEffect(() => {
    adjustTextAreaHeight();
  }, [updatedValue.content, adjustTextAreaHeight]);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handlePhraseSelect = (phrase) => {
    if (textAreaRef.current) {
      const text = textAreaRef.current.value;
      const newText = text.slice(0, cursorPosition) + phrase + text.slice(cursorPosition);

      dispatch(updateNoteDetailsBodyJSON({
        value: newText,
        key: updatedValue.key,
        noteDetailType,
      }));
    }
    const newCursorPosition = cursorPosition + phrase.length;
    setTimeout(() => {
      textAreaRef.current.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 0);
    handleCloseMenu();
  };

  const handleKeyDown = (event) => {
    if (event.key === '*') {
      setCursorPosition(event.target.selectionStart); // Save the cursor position
      handleOpenMenu(event); // Open the menu when * is pressed
    }
  };
  useEffect(() => {
    const delayTimer = setTimeout(() => {
      setIsDelayed(true);
    }, 3000);

    return () => clearTimeout(delayTimer); 
  }, []);
  useEffect(() => {
    if (isDelayed) {
      const handler = setTimeout(() => {
        if (updatedValue && updatedValue.content) {
          textAreaRef.current.value = updatedValue.content;
          setIsEdited(true);
        }
      }, debounceDelay);

      return () => clearTimeout(handler);
    }
  }, [updatedValue.heading, updatedValue.content]);

  const { copyHandler: copyContent } = useCopyToClipboard(getCopyContentFromValue(updatedValue));

  return (
    <Card elevation={4} sx={{ margin: '10px 0px', padding: '5px', width: '100%', backgroundColor: 'transparent' }}>
      <Box display="flex" alignItems="center">
        {disableHeadingEdit ? (
          <Typography flexGrow={1} color='#2E6FF3' fontWeight="bold">
            {updatedValue.heading}
          </Typography>
        ) : (
          <StyledInput
            disabled={disableHeadingEdit}
            onChange={(e) => {
              dispatch(updateNoteDetailsBodyJSON({
                value: e.target.value,
                type: 'heading',
                key,
                noteDetailType,
              }));
            }}
            heading
            value={updatedValue.heading}
          />
        )}

        <Stack direction="row" gap={1}>
          <Button
            onClick={() => {
              copyContent();
              enqueueSnackbar({
                variant: 'success',
                message: "Content has been copied"
              });
            }}
            variant='text'>
            copy
          </Button>
          <Checkbox
            color='primary'
            onChange={(e) => handleExcludedHeadings(noteDetailType, updatedValue.heading, e.target.checked)}
            checked={copyChecked}
          />
        </Stack>
      </Box>
      {updatedValue?.createdAt && (
        <Typography marginLeft="8px" color='#2E6FF3'>
          {moment.utc(value?.createdAt, "MM/DD/YY HH:mm").local().format('MM/DD/YY | HH:mm')}
        </Typography>
      )}
      <StyledTextArea
        multiline
        variant="outlined"
        ref={textAreaRef}
        value={updatedValue.content}
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          dispatch(updateNoteDetailsBodyJSON({
            value: e.target.value,
            key: updatedValue.key,
            noteDetailType,
          }));
        }}
        placeholder="Type here..."
        InputProps={{
          style: {
            height: 'auto',
          },
        }}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {phrases.map((phrase, index) => (
          <MenuItem key={phrase.id} onClick={() => handlePhraseSelect(phrase.content)}>
            {phrase.title}
          </MenuItem>
        ))}
      </Menu>
    </Card>
  );
});

export default SingleContent;
