import { useState } from "react";

export function useCopyMedicalData() {
  const [copied, setCopied] = useState(false);

  const copyHandler = (data) => {
    let contentToCopy = '';

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const entry = data[key];

        if (Array.isArray(entry.content)) {
          if (entry.content.length > 0) {
            const subHeading = entry.content[0].heading;
            contentToCopy += `${subHeading}\n`;

            entry.content.forEach((item) => {
              contentToCopy += `${item.createdAt}\n${item.content}\n\n`;
            });
          }
        } else {
          contentToCopy += `${entry.heading}\n`;
          contentToCopy += `${entry.content}\n\n`;
        }
      }
    }

    if (navigator.clipboard) {
      navigator.clipboard.writeText(contentToCopy).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      });
    } else {
      console.log("Copying content to clipboard", contentToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  return { copyHandler, copied };
}
