import { useState } from "react";

export function useCopyToClipboard(content) {
  const [copied, setCopied] = useState(false);
  
  const copyHandler = () => {
    let contentToCopy;
    if (typeof content === 'object') {
      contentToCopy = JSON.stringify(content, null, 2); // Pretty-print the JSON string
    } else {
      contentToCopy = content;
    }
    if (navigator.clipboard) {
      navigator.clipboard.writeText(contentToCopy);
    } else {
      console.log("Copying content to clipboard", contentToCopy);
    }
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return { copyHandler, copied };
}
