import { useUser } from '@clerk/clerk-react';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const SignedOutRouting = () => {
  const {isSignedIn} = useUser();
  const navigate = useNavigate();
  useEffect(()=>{
    if(!isSignedIn){
      navigate('/')
    }
  },[])
  return <></>
}

export default SignedOutRouting