import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tool: null,
};

const clinicalToolSlice = createSlice({
  name: 'clinicalTool',
  initialState,
  reducers: {
    selectTool(state, action){
      state.tool = action.payload;
    },
    closeTool(state, action){
      state.tool = null
    }
  }
});

export const { closeTool,selectTool } = clinicalToolSlice.actions;

export default clinicalToolSlice.reducer;
