import { DialogContent, Typography } from '@mui/material'
import React from 'react'

const SplashScreen = () => {
  return (
    <DialogContent sx={{ bgcolor: '#2E6FF3', textAlign: 'center'}}>
      <img rel='preload' fetchPriority='high' width="50%" src='/images/note_uploaded.png' alt='note has been uploaded' />
      <Typography variant='h3' component="h3" color='white' fontWeight="bold" textAlign="center">
        Note has been uploaded
      </Typography>
    </DialogContent>
  )
}

export default SplashScreen