import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import SideBar from '../../components/SideBar'
import { Box } from '@mui/material';
import { sideBarWidth } from '../../utils';
import { Outlet, useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import useApiRequest from '../../hooks/useHandleRequests';
import { useDispatch } from 'react-redux';
import { setPhrases } from '../../slices/customPhraseSlice';

const Layout = ({children}) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const dispatch = useDispatch();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showScreen, setShowScreen] = useState(false);
  const [version, setversion] = useState(null)
  const [softVersion, setSoftVersion] = useState(null);

  const { apiRequest: getApiRequest} = useApiRequest({
    handleResponse: (data)=> dispatch(setPhrases(data)),
    handleError: ()=> dispatch(setPhrases([])),
    showSuccessSnackbar: false
  });

  const {apiRequest: fetchVersion, loading: fetchingVersion} = useApiRequest({
    handleError: (err)=> console.log(err),
    handleResponse: (data) => {
      setversion(data?.body?.version || null)
    },
    showSuccessSnackbar: false 
  }) 

  const { apiRequest: getSoftVersion, loading: loadingSoftVersion} = useApiRequest({
    handleResponse: (data)=>{setSoftVersion(data.body.softVersion)},
    handleError: (err)=> { console.log(err)},
    showSuccessSnackbar: false
  })
  const { apiRequest: postSoftVersion, loading: postingSoftVersion} = useApiRequest({
    handleResponse: ()=>{},
    handleError: ()=>{},
    successMessage: "SoftVersion has been updated"
  })
  useEffect(()=>{
    getSoftVersion('/v2/user/soft-version')
  },[])
  
  useEffect(()=>{
    if(softVersion && softVersion !== user.publicMetadata.softVersion){
      postSoftVersion('/v2/user/soft-version','put')
    }
  },[softVersion, user.publicMetadata.softVersion])

  useEffect(()=>{
    console.log(user.publicMetadata);
    if(!version){
      fetchVersion('/v2/user/version');
    }
    else {
      getApiRequest('/v2/phrase','get', {});
    }
    if(version &&  version !== user.publicMetadata.version){
        navigate('/on-boarding');
    }
    else if (
      ((window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.tokenHandler) ||
      (window.Android && window.Android.handleToken)) && version && version === user.publicMetadata.version
    ) {
      console.log("Opened in WebView");
      navigate("/auth-token");
    } else {
      console.log("Opened in Browser");
    }
    if(version === user.publicMetadata.version && version !== null){
      setShowScreen(true);
    }
  },[version])


  if((fetchingVersion && !showScreen) || version === null)
    return null;
  return (
    <Box display="flex" justifyContent="flex-end">
      <Header
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        setMobileOpen={setMobileOpen}
      />
      <SideBar drawerOpen={drawerOpen} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
      <Box
        component="main"
        sx={{
          marginTop: '90px',
          padding: '15px',
          width: { md: `calc(100% - ${drawerOpen ? (sideBarWidth + 50) : 0}px)`, sm: '100%', xs: '100%' },
          transition: '0.2s ease-in-out',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}

export default Layout