import {
  Box,
  Button,
  Card,
  CardContent,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getCopyContentFromValue, temoNoteDetailsContent } from '../../utils';
import { CopyAll } from '@mui/icons-material';
import { useCopyToClipboard } from '../../hooks/useCopyFromClipBoard';
import { useDispatch, useSelector } from 'react-redux';
import {
  setnoteDetails,
  updateNoteDetailsBodyJSON,
} from '../../slices/noteDetialsSlice';
import SingleContent from './SingleContent';
import useApiRequest from '../../hooks/useHandleRequests';
import debounce from 'lodash/debounce';
import moment from 'moment';
import MultiContent from './MultipleContent';
const NewNoteDetailsContent = ({
  excludedHeadings,
  handleExcludedHeadings,
  noteDetailType,
  id,
}) => {
  const noteDetails = useSelector((state) => state.noteDetails.noteDetails);
  const [isEdited, setIsEdited] = useState(false);

  const { apiRequest: updateNoteContent } = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: (data) => {},
    successMessage: 'Note details has been updated',
  });
  // const updatedSummaryJson = noteDetails.summary_json?.map(item => {
  //   if (item.createdAt) {
  //     return {
  //       ...item,
  //       createdAt: moment(item?.createdAt, 'MM/DD/YY | HH:mm').utc().format('MM/DD/YY HH:mm')
  //     };
  //   }
  //   return item;
  // });
  // const updatedNoteDetails = {
  //   ...noteDetails,
  //   summary_json: updatedSummaryJson,

  // };

  useEffect(() => {
    if (isEdited) {
      const debouncedUpdate = debounce(() => {
        updateNoteContent(`/v2/note/${id}`, 'put', {
          body_json: noteDetails[noteDetailType],
          summary_json: noteDetails[noteDetailType]
          // body: updatedNoteDetails[noteDetailType].reduce((prev, value) => prev + getCopyContentFromValue(value) + '\n', '')
        });
        setIsEdited(false);
      }, 500);

      debouncedUpdate();

      return () => {
        debouncedUpdate.cancel();
      };
    }
  }, [isEdited]);
  return (
    <React.Fragment>
      <Card>
        <CardContent>
          {noteDetails[noteDetailType] &&
            Object.values(noteDetails[noteDetailType]).map(
              (summaryItem, index) => {
                if (
                  summaryItem.key === 'ekg' ||
                  summaryItem.key === 'care-update'
                ) {
                  return (
                    <MultiContent
                      key={index}
                      disableHeadingEdit
                      copyChecked={
                        !excludedHeadings.includes(summaryItem?.heading)
                      }
                      excludedHeadings={excludedHeadings}
                      handleExcludedHeadings={handleExcludedHeadings}
                      setIsEdited={setIsEdited}
                      noteDetailType={noteDetailType}
                      value={summaryItem}
                      itemKey={index}
                    />
                  );
                }
                // For all other keys, render SingleContent
                else {
                  return (
                    <SingleContent
                      key={index} 
                      disableHeadingEdit
                      copyChecked={
                        !excludedHeadings.includes(summaryItem?.heading)
                      }
                      excludedHeadings={excludedHeadings}
                      handleExcludedHeadings={handleExcludedHeadings}
                      setIsEdited={setIsEdited}
                      noteDetailType={noteDetailType}
                      value={summaryItem}
                      itemKey={index}
                    />
                  );
                }
              }
            )}
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default NewNoteDetailsContent;
