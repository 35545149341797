import {
  ContentCopy,
  Visibility,
  VisibilityOff,
  Refresh,
  Note,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Stack,
  CircularProgress,
} from '@mui/material';
import React, { useEffect } from 'react';
import TextEditor from './TextEditor';
import { useCopyToClipboard } from '../hooks/useCopyFromClipBoard';
import { useCopyMedicalData } from '../hooks/useCopyMedicalData';
import { useDispatch, useSelector } from 'react-redux';
import { updateNoteStatus } from '../slices/noteDetialsSlice';
import DraftJsEditor from './NewTextArea';
import { getCopyContentFromValue, locationType } from '../utils';
import NewNoteDetailsContent from './newNoteDetailsContent/NewNoteDetailsContent';
import { enqueueSnackbar } from 'notistack';
import useApiRequest from '../hooks/useHandleRequests';
import debounce from 'lodash/debounce';

const TextArealayout = React.memo(
  ({ id, excludedHeadings, handleExcludedHeadings, title, noteKey }) => {
    const [showContent, setShowContent] = React.useState(true);
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isDispatched, setIsDispatched] = React.useState(false);
    const [isLoader, setIsLoader] = React.useState(false);
    const noteDetails = useSelector((state) => state.noteDetails.noteDetails);
    const { copyHandler } = useCopyMedicalData(
      noteDetails[noteKey] && noteDetails[noteKey]
    );
    const { apiRequest: updateNoteContent } = useApiRequest({
      handleError: (err) => console.log(err),
      handleResponse: (data) => {},
      successMessage: 'Note details has been updated',
    });
    const handleOpenModal = () => {
      setIsModalOpen(true);
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
    const handleYes = () => {
      const updatedStatus =
        title === 'Disposition' ? 'FOLLOW_UP_UPLOADED' : 'NEW_UPLOADED';
      dispatch(updateNoteStatus({ key: 'status', value: updatedStatus }));
      if (title === 'Disposition') {
        dispatch(updateNoteStatus({ key: 'followup', value: '' }));
        dispatch(updateNoteStatus({ key: 'followup_json', value: {} }));
        setIsLoader(true);
      } else {
        dispatch(updateNoteStatus({ key: 'summary', value: '' }));
        dispatch(updateNoteStatus({ key: 'summary_json', value: {} }));
        setIsLoader(true);
      }
      console.log('Re-transcribing the note/disposition');
      handleCloseModal();
      setIsDispatched(true);
    };

    const handleNo = () => {
      handleCloseModal();
    };

    useEffect(() => {
      if (isDispatched) {
        const debouncedUpdate = debounce(() => {
          updateNoteContent(`/v2/note/${id}/status`, 'put', {
            status: noteDetails.status,
          });
          updateNoteContent(`/v2/note/${id}`, 'put', {
            body: '',
            body_json: {},
          });
          setIsDispatched(false);
        }, 500);

        debouncedUpdate();

        return () => {
          debouncedUpdate.cancel();
        };
      }
    }, [isDispatched]);
    return noteDetails.status === 'ERROR' ? (
      <Stack
        justifyContent='center'
        marginTop='30px'
        gap={2}
        alignItems='center'
      >
        <Typography color='error' variant='h5' fontWeight={600}> Something Went Wrong! </Typography>
      </Stack>
    ) : noteDetails.status !== 'COMPLETE' && isLoader ? (
      <Stack
        justifyContent='center'
        marginTop='30px'
        gap={2}
        alignItems='center'
      >
        <CircularProgress />
      </Stack>
    ) : (
      <Box border='1px solid #2E6FF3' borderRadius={2}>
        <Box
          sx={{ borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          bgcolor='#2E6FF3'
          padding='5px 10px'
        >
          <Typography color='white' variant='h6'>
            {title}
          </Typography>
          <Box>
            <IconButton sx={{ color: 'white' }} onClick={handleOpenModal}>
              <Refresh />
            </IconButton>

            {/* Modal */}
            <Dialog
              open={isModalOpen}
              onClose={handleCloseModal}
              sx={{
                '& .MuiDialog-paper': {
                  border: '1px solid #2E6FF3',
                  borderRadius: '8px',
                },
              }}
            >
              <DialogTitle></DialogTitle>
              <DialogContent>
                <Typography variant='body1'>
                  Are you sure you want to re-transcribe the note/disposition?
                </Typography>
                <Typography variant='body1' color='grey'>
                  {`(ekg and care update will not be re-transcribed)`}
                </Typography>
              </DialogContent>

              <DialogActions>
                <Button
                  onClick={handleYes}
                  variant='contained'
                  sx={{
                    backgroundColor: '#2E6FF3',
                    color: 'white',
                    '&:hover': { backgroundColor: '#4A92F7' },
                  }}
                >
                  Yes
                </Button>
                <Button onClick={handleNo} variant='outlined' color='primary'>
                  No
                </Button>
              </DialogActions>
            </Dialog>
            <IconButton
              sx={{ color: 'white' }}
              onClick={() => setShowContent((prev) => !prev)}
            >
              {showContent ? <Visibility /> : <VisibilityOff />}
            </IconButton>
            <IconButton
              onClick={() => {
                copyHandler(noteDetails[noteKey]);
                enqueueSnackbar({
                  variant: 'success',
                  message: 'content has been copied',
                });
              }}
              sx={{ color: 'white' }}
            >
              <ContentCopy />
            </IconButton>
          </Box>
        </Box>
        {/* {
          showContent && <DraftJsEditor id={id} value={noteDetails[noteKey]}  handleChange={(value)=>dispatch(updateNoteDetails({key: noteKey, value }))} />
        } */}
        {showContent && (
          <NewNoteDetailsContent
            excludedHeadings={excludedHeadings}
            handleExcludedHeadings={handleExcludedHeadings}
            id={id}
            noteDetailType={noteKey}
          />
        )}
      </Box>
    );
  }
);

export default TextArealayout;
