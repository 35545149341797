import React, { useEffect, useState } from 'react'
import Sections from '../../components/Sections';
import CustomPhrases from '../../components/customPhrases/CustomPhrases';
import useApiRequest from '../../hooks/useHandleRequests';
import { useDispatch, useSelector } from 'react-redux';
import { setNoteTemplates } from '../../slices/customNotes';
import { Box, Button, CircularProgress, Stack } from '@mui/material';
import { Save } from '@mui/icons-material';
import { getnoteTemplatesPostingData, tourStepsCustomNote } from '../../utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { closeTutorial } from '../../slices/tutorialSlice';

import { useUser } from '@clerk/clerk-react';
import { driver } from 'driver.js';
import 'driver.js/dist/driver.css';

const CustomNoteTemplate = () => {
  const {user} = useUser();
  const isTutorialOpen = useSelector((state) => state.tutorial.isTutorialOpen);
  const location = useLocation();
  const [isCurrentPage, setIsCurrentPage] = useState(false);
  useEffect(() => {
    const match = location.pathname === '/custom-note-template';
    if (match) {
      setIsCurrentPage(true);
    } else {
      setIsCurrentPage(false);
    }
  }, [location]);
  const [tour, setTour] = useState(false);
  const startTour = () => {
    const driverObj = driver({
      popoverClass: 'driverjs-theme',
      showProgress: true,
      animate: true,
      prevBtnText: "Back",
      nextBtnText: "Next",
      allowClose: true,
      steps: tourStepsCustomNote,

      onDestroyStarted: () => {
        setTour(false)
        localStorage.setItem(`hasSeenTour_/custom-note-template`, 'true');
        dispatch(closeTutorial());
        driverObj.destroy();
      },
  
    });
    
    driverObj.drive();
  }
  useEffect(() => {
    if(tour){
      startTour();
    }
  }, [tour]);
  useEffect(()=>{
    const tourSeen = localStorage.getItem(`hasSeenTour_/custom-note-template`)
    if(user?.publicMetadata?.tutorial === 0 && tourSeen === "false"){
      setTour(true)
    }
  },[])
  useEffect(()=>{
    if(isTutorialOpen && isCurrentPage){
      setTour(true)
    }
  },[isTutorialOpen])
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const noteTemplates = useSelector(state => state.noteTemplates);
  const [edited, setEdited] = useState(false);
  const { apiRequest: getConfigs, loading: isLoading} = useApiRequest({
    handleResponse: (data)=> {
      if(data.msg) {
        Object.entries(data.msg).forEach(([key, value]) => {
          if(!Array.isArray(value))
            navigate('/on-boarding');
        })
      }
      dispatch(setNoteTemplates(data.msg))
    },
    handleError: (err)=> { console.log(err)},
    showSuccessSnackbar: false
  })

  const { apiRequest: postConfigs, loading: postingConfigs} = useApiRequest({
    handleResponse: ()=>{},
    handleError: ()=>{},
    successMessage: "Configs has been updated"
  })

  useEffect(()=>{
    getConfigs('/v2/user/config');
  },[])

  useEffect(()=>{
    if(edited){
      postConfigs('/v2/user/config','post', getnoteTemplatesPostingData(noteTemplates))
      setEdited(false)
    }
  },[edited])

  return (
    <React.Fragment>
      {/* <Stack marginBottom="15px" direction="row-reverse">
        <Button variant='contained' disabled={postingConfigs} startIcon={<Save />} onClick={()=> postConfigs('/v2/user/config','post', getnoteTemplatesPostingData(noteTemplates))}>
          Save Changes
        </Button>
      </Stack> */}
      <Sections postConfigs={postConfigs} setEdited={setEdited} />
      <CustomPhrases />
    </React.Fragment>
  )
}

export default CustomNoteTemplate