import { Box, Card, CardContent, Typography, Grid2 as Grid, Button, Stack, IconButton, List, ListItemButton, ListItemText } from '@mui/material';
import React from 'react'
import { useDispatch } from 'react-redux';
import { closeTool } from '../../slices/clinicalToolSlice'
import { Add, Close } from '@mui/icons-material';

const VTE = ({preSelected}) => {
  const dispatch = useDispatch();
  return (
    <Card variant='elevation' elevation={3} sx={{ width: '100%', marginBottom: '15px', backgroundColor: '#EDF2FF' }}>
      <CardContent>
        <Stack direction="row-reverse">
          <IconButton onClick={() => dispatch(closeTool())} size='large' color='primary'>
            <Close />
          </IconButton>
        </Stack>
        <Typography color='#202224' fontWeight={500} fontSize="24px">
        </Typography>
            <Grid container component="main" spacing={3}>
              <Grid sx={{ margin: '15px 0px' }} size={{ lg: 6, md: 6, sm: 6, xs: 6 }}>
                <Typography color='#202224' fontWeight={500} fontSize="18px">
                </Typography>
                    <Typography color='#5E5E5E' fontWeight={500} fontSize="16px">
                    </Typography>
              </Grid>
              <Grid size={{ lg: 6, md: 6, sm: 6, xs: 6 }}>
                <List sx={{ bgcolor: 'white', margin: '10px 0px', padding: '0px', borderRadius: '8px' }}>
                          <ListItemButton
                            disabled
                            dense
                            sx={{
                              borderBottom: '1px solid rgba(0, 0, 0, 0.25)',
                              '&.Mui-selected': {
                                backgroundColor: '#2E6FF3',
                                color: 'white'
                              },
                              '&.Mui-selected:hover': {
                                backgroundColor: '#2E6FF3',
                                color: 'white'
                              }
                            }}
                          >
                            <ListItemText
                              primaryTypographyProps={{
                                fontSize: '14px',
                                fontWeight: 500,
                                maxWidth: '100%',
                                textAlign: 'center'
                              }}
                            />
                            {/* <Typography color={selected ? 'white' : 'rgba(0, 0, 0, 0.38)'} fontSize="1px" fontWeight={600}>
                              {sectionItem.value > 0 ? '+' : ''}{sectionItem.value}
                            </Typography> */}
                          </ListItemButton>
                </List>
              </Grid>
            </Grid>
      </CardContent>
      <Box bgcolor="white" padding="15px" marginBottom="30px">
        <Grid component="main" container spacing={5}>
          <Grid size={{ lg: 8, md: 8, sm: 12, xs: 12 }}>
            <Typography>
               INPUTS:
            </Typography>
          </Grid>
          <Grid size={{ lg: 4, md: 4, sm: 12, xs: 12 }}>
          </Grid>
        </Grid>
        <Stack marginTop="15px" direction="row-reverse">
          <Button onClick={() => {
           // dispatch(updateNoteDetails({ key: 'summary_json', value: getContent() }));
            dispatch(closeTool())
          }
          } sx={{ bgcolor: '#2E6FF3' }} variant='contained' color='primary' size='large' startIcon={<Add />}>
            Insert
          </Button>
        </Stack>
      </Box>
    </Card>
  )
}

export default VTE;