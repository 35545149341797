import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  mobileNotes: [],
  notes: [],
  filters: {
    location: '',
    gender: '',
    noteType: 'All Notes',
    search: ''
  },
  pagination: {
    currentPage: 1,
    notesPerPage: 10,
    total: 10,
    pages: 1
  },
};

const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setMobileNotes(state, action) {
      state.mobileNotes = action.payload;
      state.mobileNotes = state.mobileNotes.sort((a,b)=> new Date(b.createdAt) - new Date(a.createdAt))
    },
    setNotes(state, action) {
      const {  
        body,
        pagination
      } = action.payload;
      state.notes = body;
      state.pagination.pages = pagination.pages;
      state.pagination.total = pagination.total
    },
    addNote(state, action){
      state.notes.push(action.payload)
    },
    removeNote(state, action){
      state.notes = state.notes.filter(note => note.id !== action.payload)
    },
    updatepatient(state, action){
      const { noteId, patientData, location, locationType } = action.payload;

      const noteIndex = state.notes.findIndex((note) => note.id === noteId);

      if (noteIndex !== -1) {
        state.notes[noteIndex] = {
          ...state.notes[noteIndex],
          location,
          locationType,
          patient: {
            ...state.notes[noteIndex].patient,
            ...patientData,
          }
        };
      }
    },
    setNoteTypeFilter(state, action) {
      state.filters.noteType = action.payload;
      state.pagination.currentPage = 1
    },
    setGenderFilter(state, action) {
      state.filters.gender = action.payload;
    },
    setLocationFilter(state, action) {
      state.filters.location = action.payload;
    },
    setCurrentPage(state, action) {
      state.pagination.currentPage = action.payload;
    },
    setNotesPerPage(state, action){
      state.pagination.notesPerPage = action.payload;
    },
    updateSearch(state, action){
      state.filters.search = action.payload;
      state.pagination.currentPage = 1
    }
  },
});

export const { setNotes,addNote, setNoteTypeFilter, setLocationFilter, updateSearch, setCurrentPage, setGenderFilter, setNotesPerPage, removeNote, setMobileNotes, updatepatient } = notesSlice.actions;

export default notesSlice.reducer;
