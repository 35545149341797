import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  Editor,
  EditorState,
  getDefaultKeyBinding,
  Modifier,
  CompositeDecorator,
  ContentState,
  SelectionState,
} from "draft-js";
import "draft-js/dist/Draft.css";
import { useSelector } from "react-redux";
import useApiRequest from "../hooks/useHandleRequests";
import { Menu, MenuItem, Paper, Box } from "@mui/material";
import moment from "moment";

const BlueAndBoldAllCaps = (props) => {
  return (
    <span style={{ color: "#2E6FF3", fontWeight: "bold", fontSize: '18px' }}>
      {props.children}
    </span>
  );
};

// Function to find all-caps words
const findAllCapsWords = (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();
  const regex = /^(?:[A-Z\s()-]+(?:\([A-Za-z]+\))?:)\s*/gm;
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

const findDates = (contentBlock, callback, contentState) => {
  const text = contentBlock.getText();
  const regex = /\b\d{2}\/\d{2}\/\d{2}\s*\|\s*\d{2}:\d{2}\b/g;
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

const UTCtoLocalDate = (props) => {
  const [date, time] = props.decoratedText.split('|').map(part => part.trim());
  return (
    <span>
      {moment.utc(date + ' ' + time, "MM/DD/YY HH:mm").local().format('MM/DD/YY | HH:mm')}
    </span>
  );
};

const allCapsDecorator = {
  strategy: findAllCapsWords,
  component: BlueAndBoldAllCaps,
};

const allDatesDecorator = {
  strategy: findDates,
  component: UTCtoLocalDate
}

const debounceDelay = 1000;

const DraftJsEditor = ({ id = 123, value = '', handleChange, placeholder = "Add " }) => {
  const { phrases } = useSelector(state => state.customPhrases);
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(ContentState.createFromText(value), new CompositeDecorator([allCapsDecorator, allDatesDecorator]))
  );
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [showDropdown, setShowDropdown] = useState(false);
  const editorRef = useRef(null);
  const { apiRequest: updateNoteContent } = useApiRequest({
    handleError: (err) => console.log(err),
    handleResponse: (data) => console.log(data),
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      if (debouncedValue !== value) {
        setDebouncedValue(value);
      }
    }, debounceDelay);
    return () => clearTimeout(handler);
  }, [value , debounceDelay]);

  useEffect(() => {
    updateNoteContent(`/v2/note/${id}`, "put", { body: debouncedValue });
    // Maintain the selection state while updating content
    const currentSelection = editorState.getSelection();
    const contentState = ContentState.createFromText(debouncedValue);
    const newEditorState = EditorState.createWithContent(contentState, new CompositeDecorator([allCapsDecorator, allDatesDecorator]));
    
    const updatedEditorState = EditorState.forceSelection(newEditorState, currentSelection);
    setEditorState(updatedEditorState);
  }, [debouncedValue, updateNoteContent, id]);

  const onChange = (newEditorState) => {
    const newValue = newEditorState.getCurrentContent().getPlainText();
    setEditorState(newEditorState);
    handleChange && handleChange(newValue);
  };

  const Dropdown = useCallback(
    () => (
      <Menu
        open={showDropdown}
        onClose={() => setShowDropdown(false)}
        anchorReference="anchorPosition"
        PaperProps={{
          style: {
            maxHeight: 200,
            overflow: 'auto',
          },
        }}
      >
        {phrases.map((option, index) => (
          <MenuItem
            key={index}
          >
            {option.title}
          </MenuItem>
        ))}
      </Menu>
    ),
    [phrases]
  );

  return (
    <Box style={{ position: "relative" }}>
      <Paper
        ref={editorRef}
        elevation={0}
        sx={{ padding: "10px", minHeight: "100px", border: 'none', borderRadius: '8px' }}
      >
        <Editor
          editorState={editorState}
          onChange={onChange}
          placeholder={placeholder}
        />
      </Paper>
      {showDropdown && <Dropdown />}
    </Box>
  );
};

export default DraftJsEditor;
